<template>
  <div class="noScroll">
    <v-row align="center" justify="center">
      <v-col cols="11" md="8" style="text-align: justify">
        <h1>Application Terms of Service</h1>
        <small>Last updated: June 2, 2022</small><br><br>
        These Application Terms of Service (“Application Terms”) together with any plan or feature limits, product disclaimers, or other restrictions presented to you in the application from which these terms are linked (“Application”) (collectively, the “Agreement”) govern your access and use of the Application. As used in this Agreement, “Bayon App,” “Us” or “We” refers to Bayon App, Platform Provider.
        <br><br> By downloading, installing, accessing, or using the Application you agree to be bound by this Agreement on behalf of yourself and the organization you represent, if any. If you are accepting on behalf of an organization, do not install or access this Application unless you are authorized to represent that organization. PLEASE READ THIS AGREEMENT CAREFULLY, AS IT CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU AND BAYON APP. If you do not agree to this Agreement, you are not authorized to download, install, access, use, or copy the Application and Bayon App does not grant you a license, nor any right to access or use the Application.
        <br><br> THE AGREEMENT CONTAINS PROVISIONS REQUIRING THAT YOU AGREE TO THE USE OF ARBITRATION TO RESOLVE ANY DISPUTES ARISING UNDER THE AGREEMENT RATHER THAN A JURY TRIAL OR ANY OTHER COURT PROCEEDINGS, AND TO WAIVE YOUR PARTICIPATION IN CLASS ACTION OF ANY KIND AGAINST BAYON APP.
        <br><br><b>1. Privacy Policy</b>
        <br> All information provided by you or resulting from your use of the Application (excluding any Bayon App for Teams functionality, which is addressed in Section 4 below) is collected, used, maintained, shared, and destroyed in accordance with the Application Privacy Policy.
        <br><br><b>2. Scope of License</b>
        <br>You are granted a non-exclusive, personal, revocable, non-transferable license to use the Application on the device for which it is provided and that you own or control, subject to any terms of service, usage rules, or other terms and conditions provided by the Third Parties (defined below).
        <br><br>You agree to use the Application, and all related software or updates provided to you by Bayon App in accordance with this Agreement and not to modify, decompile, reverse engineer, or create derivative works of the Application. Except as otherwise required by applicable law or regulation, Bayon App, at any time in its sole discretion and without prior notice, may (i) terminate your use and license of the Application, (ii) expand, reduce, or suspend your use of the Application, (iii) update, revise, or modify the Application, or (iv) discontinue support for the Application.
        <br><br>The Application is licensed, not sold. It is owned, as applicable, by Bayon App, their affiliates, agents, or licensors and is protected by United States copyright laws and international treaty provisions. Except for the limited license expressly granted in this Agreement, Bayon App, their affiliates, agents, or licensors reserve all right, title, and interest in and to the Application. All content, trademarks, services marks, trade names, logos, and icons are proprietary to Bayon App, their affiliates, agents, or licensors. You may not remove any proprietary notices (e.g., copyright and trademark notices) from the Application. 
        <br><br><b>3. Bayon App for Teams</b>
        <br>The Application may be used as part of the Bayon App for Teams offerings to which you or your organization subscribe to under a separate agreement with Bayon App (“Subscription Agreement”). Your use of the Application as part of Bayon App for Teams is subject to both this Agreement and the Subscription Agreement. In the event of a conflict between this Agreement and the Subscription Agreement, the Subscription Agreement controls. You understand that when your device is used as part of Bayon App for Teams, Bayon App will process your personal data on behalf of the Bayon App for Teams subscriber that is associated with your device.
        <br><br><b>4. Availability</b>
        <br>We do not guarantee the continuous availability of the Application or of any specific feature(s) or services of the Application. Bayon App will inform you of any significant changes to the Application it may occasionally make. Bayon App may impose usage or service limits, suspend service, or block certain kinds of usage at our sole discretion. The accuracy and timeliness of data received is not guaranteed; delays or omissions may occur. The speed and quality of the Application may vary and the Application is subject to unavailability, including emergencies, third party service failures, transmission, equipment or network problems or limitations, interference, signal strength, and maintenance and repair. The Application may be interrupted, refused, limited, or curtailed. Bayon App is not responsible for any failures to maintain the confidentiality, security, accuracy, or quality of your data, messages, or pages whether or not related to interruptions or performance issues with the Application.
        <br><br><b>5. User Responsibilities</b>
        <br>A device enabled to access the Internet is required to utilize the Application. You are solely responsible for ensuring that your device is sufficient and compatible for use with the Application. Bayon App may not be used to misrepresent or to act on behalf of others. All messages you published through the Application shall identify you as the user.
        <br><br>You will not use the Application to:
        <br><br>
          <ul>
            <li>Engage in any activities that are illegal;</li>
            <li>Transmit content that infringes on any person's intellectual property rights or that is otherwise unlawful;</li>
            <li>Alter the attribution of origin in electronic mail messages or posting;</li>
            <li>Interfere with, disrupt, alter, or modify the Application, or any part thereof, or create an undue burden on the Application or the networks or services connected to the Application.</li>
          </ul>
          <br>You shall at all times abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with your use of the Application, including those related to data privacy, international communications and the transmission of technical or personal data. If Bayon App is contacted with a complaint regarding or arising from your use of the Application, Bayon App may deem your service usage in violation of this Agreement and terminate your account without notice.
        <br><br><b>6. Subscription Terms, Renewals, and Cancellations</b>
        <br>Certain features require a subscription via Apple App Store, as applicable (each, an “App Store”). Such features (“Paid Application Services”) will be provided to you on a subscription basis for the length of term specified during the subscription process (“Subscription Term”). All of your subscriptions to Paid Application Services will automatically renew for periods equal to your initial Subscription Term, and you will be charged at our then-current rates unless you cancel your subscription through the App Store (defined below) prior to your next scheduled billing date.
        <br><br><b>7. Billing</b>
        <br><br>7.1 Recurring Billing
        <br>In order to access Paid Application Services for which we require a subscription fee, you will be required to provide the applicable App Store with your credit card information (“Payment Method”). By providing a Payment Method you are authorizing us to charge your Payment Method through the App Store for the subscription fees associated with the Paid Application Services that you sign up for. Any Payment Method that you provide must be valid, and kept current by you during the Subscription Term. By providing us with a Payment Method, you represent and warrant that you are authorized to use such Payment Method. Your Payment Method for the Paid Application Services will be billed on the day that you sign up for such Paid Application Services, regardless of whether you have fully configured the Paid Application Services as of that date.
        <br><br>7.2 Price Changes
        <br>We reserve the right to change the subscription fees that we charge for the Paid Application Services, at any time in our sole discretion, provided that we give you at least thirty (30) days' prior notice of such changes. Unless otherwise specified in such notice to you, any changes to the subscription fees will take effect in the billing period immediately following our notice to you.
        <br><br>7.3 Cancellation
        <br>If you do not want your subscription to renew automatically, or if you want to change or terminate your subscription, you must log in to your App Store account and follow the instructions to manage or cancel your subscription, even if you have otherwise deleted your account with us or if you have deleted the Application from your device. For example, if you subscribed using your Apple ID, cancellation is handled by Apple, not Bayon App. To cancel a purchase made with your Apple ID, go to Settings > iTunes & App Stores > [click on your Apple ID] > View Apple ID > Subscriptions, then find your subscription and follow the instructions to cancel. 
        <br><br>7.4 No Refunds
        <br>SUBSCRIPTION FEES ARE SUBJECT TO THE APP STORE REFUND POLICIES AND ARE ISSUED SOLELY AT THE DISCRETION OF THE APP STORE. YOU WILL BE BILLED IN FULL FOR THE SUBSCRIPTION TERM IN WHICH YOU CANCEL AND NO REFUNDS WILL BE PROVIDED FOR THE UNUSED PORTION OF SUCH SUBSCRIPTION TERM. We may, in our sole discretion, provide a refund, discount, or credit (“Credits”) to you in a specific instance, however the provision of Credits in a specific instance does not entitle you to Credits in the future for similar instances or obligate us to provide additional Credits.
        <br><br><b>8. Referral Program</b>
        <br>we don't any referral program yet at this moment. 
        <br><br><b>9. Third Parties</b>
        <br>The Agreement forms a contract between you and Bayon App, as applicable. The Third Parties, do not own and are not responsible for the Application. The Third Parties are not providing any warranty for the Application. They are not responsible for maintenance or other support services for it and shall not be responsible for any other claims, losses, liabilities, damages, costs or expenses with respect to the Application. Any inquiries or complaints relating to the use of the Application, must be directed to the correct address provided in the “contact information” below.
        <br><br>By accepting this Agreement, you also accept that you comply with all applicable Third Party terms of agreement when using the Application. You acknowledge and agree that the Third Parties and their subsidiaries, are third party beneficiaries of this Agreement and that, upon your acceptance of the terms and conditions of it, they will have the right to enforce this Agreement against you as a third party beneficiary of this Agreement.
        <br><br><b>10. Beta Services</b>
        <br>From time to time, we may released any new functionality to improve our services, each of functionality may require additional pay or free for testing. You also agreed if additional fee will apply to your account as state on additional application service.
        <br><br><b>11. Disclaimer of Warranties</b>
        <br>THE APPLICATION IS MADE AVAILABLE TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. AS SUCH, YOUR USE OF THE APPLICATION IS AT YOUR OWN DISCRETION AND RISK. BAYON APP MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, ACCURACY, OR RELIABILITY OF THE APPLICATION, ITS SAFETY OR SECURITY, OR THE SERVICES CONTENT. ACCORDINGLY, BAYON APP IS NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE APPLICATION'S INOPERABILITY, UNAVAILABILITY, OR SECURITY VULNERABILITIES. BAYON APP EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
        <br><br><b>12. Limitation of Liability</b>
        <br>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL BAYON APP BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE APPLICATION, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL THEORY, WHETHER OR NOT BAYON APP HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.
        <br><br>YOU AGREE THAT THE AGGREGATE LIABILITY OF BAYON APP TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF RELATING TO THE USE OF OR ANY INABILITY TO USE THE APPLICATION (INCLUDING ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICES) OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO AND SHALL NOT EXCEED THE GREATER OF THE AMOUNTS YOU PAID TO BAYON APP FOR ACCESS TO THE APPLICATION AND $0.1111.
        <br><br>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        <br><br><b>13. Third Party Software</b>
        <br>The Application and future enhancements may contain certain third party components (“Third Party Components”) which may be provided to you under terms and conditions which are different from this Agreement, or which require Bayon App to provide you with certain notices and/or additional information. Your use of each Third Party Component will be subject to the terms and conditions of those other terms and conditions. BAYON APP MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, WITH REGARD TO ANY THIRD-PARTY COMPONENTS. ALL THIRD-PARTY COMPONENTS ARE PROVIDED “AS-IS,” WITHOUT WARRANTIES OF ANY KIND. IN NO EVENT WILL BAYON APP BE LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE THIRD PARTY COMPONENTS, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
        <br><br><b>14. Messaging</b>
        <br>As part of the Application, you may receive push notifications, text messages, alerts, or other types of messages sent to you outside or inside the Application (“Push Messages”). You have control over the Push Messages settings, and can opt in or out of these Push Messages through the Application or through your device's operating system (with the exception of Urgent Notifications described above).
        <br>Please be aware that messaging fees may apply depending on the message plan you have with your wireless carrier. Bayon App disclaims all liability for Push Messages sent erroneously due to technical failures or errors.
        <br><br><b>15. Application Updates</b>
        <br>Bayon App may require you to update your version of the Application at any time. While effort will be made to retain your personal settings and preferences, there is still the possibility that they may be lost.
        <br><br><b>16. Changes to the Terms</b>
        <br>Bayon App reserves the right to make modifications to this Agreement at any time. If a revision materially alters your rights we will use reasonable efforts to make such information available to you. In some instances, you may be required to indicate your consent to the revised terms in order to continue accessing the Application. Unless otherwise specified, any modifications to this Agreement will take effect at the start of Subscription Term following the notice. If you do not agree with the revised terms, your sole and exclusive remedy will be not to renew your Subscription. It is your responsibility to visit the terms section of the Application to review the most current terms and conditions.
        <br><br><b>17. Governing Law</b>
        <br>This Agreement is governed by the laws of the Kingdom of Cambodia without regard to conflict of law principles. To the extent that any lawsuit or court proceeding is permitted hereunder, you and Bayon App agree to submit to the personal and exclusive jurisdiction of the KINGDOM OF CAMBODIA.
        <br><br><b>18. Contact Information</b>
        <br>This Application is operated by Bayon App Platform, located at No1036 Road5 Sangkat Mittapheap, Khan Russei Keo, Phnom Penh, Cambodia. You may contact us by sending correspondence to the foregoing address (Attention: Admin Department) or by emailing us at support@bayonapp.com. Any “abuse” complaints can be directed to info@bayonapp.com 
        <br><br><b>19. Miscellaneous</b>
        <br>This Agreement and the related terms referenced above constitute the entire understanding and agreement between us and you with respect to the transactions contemplated in this Agreement and supersedes all prior or contemporaneous oral or written communications with respect to the subject matter of this Agreement, all of which are merged into this Agreement. Except as provided above, this Agreement may not be modified, amended or in any way altered except by an instrument in writing signed by authorized representatives of both parties. In the event any provision of this Agreement is found invalid or unenforceable pursuant to judicial decree, the remainder of this Agreement will remain valid and enforceable according to its terms. Any failure by Bayon App to strictly enforce any provision of this Agreement will not operate as a waiver of that provision or any subsequent breach of that provision. The disclaimers and limitations of liability will survive any termination or expiration of this Agreement.
        <br><br><b>20. User data privacy</b>
        <br>All users are downloading BAYON we not stored any information unless you register to become a member. To become a member our platform will required your phone number, with associated  your country location. So our platform will store only phone number you provide as main requirements. 
        <br>After you are no longer use BAYON App you can request to our support team to delete your data via email: <a href="mailto:support@bayonapp.com">support@bayonapp.com</a> or you might be able to delete your account from your account profile page.
        <br><br>
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name: "webviewTermofuse",
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {  }
};
</script>
<style scoped>
.noScroll {
  width: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
</style>

